@media only screen and (min-device-width: 393px) and (max-device-width: 430px) and (min-device-height: 852px) and (max-device-height: 932px)  {
  .header-box {
    top: 0px; /* Positions the box 70px from the top */
    padding-left: 20px;
  }
  .header-box .top-text {
    text-align: left; /* Center-aligns the text */
    font-size: 1.1rem; /* Small text size */
    width: 100%;
    font-family: 'DERegular', sans-serif; /* Applies the custom font */
    margin-bottom: -27px;
    transform: scale(1.2, 1);   /* Expand horizontally */
    transform-origin: left; 
    font-weight: 500;

  }
  .header-box .main-text {
    text-decoration: none;
    margin-top: 14px;
    text-align: left; /* Center-aligns the text */
    font-size: 1.8rem; /* Larger text size */
    font-weight: 500;
    vertical-align: top;
    font-family: 'DERegular' !important; /* Applies the custom font */
    transform: scale(1.2, 1);   /* Expand horizontally */
    transform-origin: left; 
  }
  
  .header-box .bottom-text {
    display: flex;
    justify-content: space-between; /* Distributes space between items */
    align-items: center; /* Aligns items vertically */
    font-weight: 500;
    font-family: 'DERegular', sans-serif; /* Applies the custom font */
    transform: scale(1.2, 1);   /* Expand horizontally */
    transform-origin: left; 
    margin-top: -12px;
  }
  
  .header-box .bottom-text .left-text {
    text-align: left; /* Aligns text to the left */
    font-size: 0.8rem; /* Small text size */
    
    font-weight: 500;
    font-family: 'DERegular', sans-serif; /* Applies the custom font */
    
    margin-top: 0px !important;
  }
  
  .header-box .bottom-text .right-text {
    text-align: right; /* Aligns text to the right */
    font-size: 1.2rem; /* Small text size */
  
    font-family: 'DERegular', sans-serif; /* Applies the custom font */
    margin-top: 0px !important;
    transform: scale(1.0, 1);   /* Expand horizontally */
    transform-origin: left; 
  }
  .header-text-box{
    width: auto;
    text-align: left;
    margin-left: 0px !important;
    padding: 10px;
    display: inline-block;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .box-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    font-family: 'DERegular', sans-serif; /* Applies the custom font */
    margin-top: 0px !important;
    transform: scale(1.0, 1);   /* Expand horizontally */
    transform-origin: left; 
  }

  .box-number {
    width:auto !important;
    max-width: 50%;
    font-size: 10rem; /* Small text size */
  
    font-family: 'DERegular', sans-serif; /* Applies the custom font */
    margin-top: -200px !important;
    transform: scale(1.0, 1);   /* Expand horizontally */
    transform-origin: left; 
  }
  
  .content-box{
    height: 200px !important;
  }
  
  .hero-image-style{
    position: absolute; /* Allows the image to overflow outside the span */
    top: -10; /* Adjust positioning as needed */
    left: 0; /* Adjust positioning as needed */
    width: 280px!important;
    height: 150px!important;
    margin-top: -10px;
    margin-left: -100px;
  
  }
  .quiz-title {
    font-size: 1.7rem;
    margin-left: -10px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .timenow{
    height: 14px !important;
    width: 14px !important;
    margin-top: -3px;
  }
}
