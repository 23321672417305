
/* Media query for mobile view */
@media (max-width: 950px) {
  .content {
    flex-direction: column;
    overflow-y: unset; /* Remove vertical scrolling */
    overflow-x: unset; /* Disable horizontal scrolling */
    height: auto; /* Let the content expand */
    margin-top: 200px;
    width: 85%;
   
  }

  .content-box {
    min-width: 100%; /* Take full width of the container */
    height:190px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-left: 0;
    margin-right: 0;
  }
  .header {
    position: relative;
    height: auto;
    display: flex;
    justify-content: center; /* Centers the box horizontally */
  }
  
  .header-box {
    width: 85%;
    position: absolute;
    top: 0px; /* Positions the box 70px from the top */
    height: auto; /* Height of the box */
    border: 2px solid #01FF76; /* Outline of the box */
    vertical-align: top; /* Aligns the box with the top of its container */
    text-align: left;
    background-color: #01FF76;
    display: inline-block;
    align-items: left; /* Vertically centers items */
   padding: 0px !important;
   padding-top: 20px !important;
   padding-bottom: 20px !important;
  }
  
  .header-text-box{
    width: auto;
    text-align: left;
    margin-left: 0px !important;
    padding: 20px;
    display: inline-block;
  
  }
  
  
  .header-text-box-right{
    width: auto;
  
    display: inline-block;
    vertical-align: top; /* Aligns the box with the top of its container */
    text-align: right;
  }
  
  
  .header-box .top-text {
    text-align: left; /* Center-aligns the text */
    font-size: 1rem; /* Small text size */
    width: 100%;
    /* Applies the custom font */
  }
  
  .header-box .main-text {
    margin-top: 14px;
    text-align: left; /* Center-aligns the text */
    font-size: 2rem; /* Larger text size */
    font-weight: bold; /* Makes the text bold */
    vertical-align: top;
    /* Applies the custom font */
  }
  
  .header-box .bottom-text {
    display: flex;
    justify-content: space-between; /* Distributes space between items */
    align-items: center; /* Aligns items vertically */
    font-weight: 500;
    font-family: 'DERegular', sans-serif; /* Applies the custom font */
    transform: scale(1.2, 1);   /* Expand horizontally */
    transform-origin: left; 
    margin-top: -12px;
  }
  
  .header-box .bottom-text .left-text {
    text-align: left; /* Aligns text to the left */
    font-size: 0.8rem; /* Small text size */
    
    font-weight: 500;
    font-family: 'DERegular', sans-serif; /* Applies the custom font */
    
    margin-top: 0px !important;
  }
  
  .header-box .bottom-text .right-text {
    text-align: right; /* Aligns text to the right */
    font-size: 1.2rem; /* Small text size */
  
    font-family: 'DERegular', sans-serif; /* Applies the custom font */
    margin-top: 0px !important;
    transform: scale(1.0, 1);   /* Expand horizontally */
    transform-origin: left; 
  }
  
  
  .img-quiz{
    display: inline-block; /* Ensure span takes only as much width as needed */
    overflow: visible; /* Allows content to overflow outside */
    position: absolute; /* Positions children absolutely relative to the span */
    text-align: right;
  }
  .hero-image-style{
    position: absolute; /* Allows the image to overflow outside the span */
    margin-left: -80px;
    margin-top: -10px;
    width: 250px !important;
    height: 124px!important;
  }
 
  
  .box-number {
    width:auto !important;
    font-size: 140px;
    font-weight: bold;
    margin-right: 20px;
    max-width: 30%;
    height: 50px;
    /* Applies the custom font */
  }
  
  .box-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    /* Applies the custom font */
  }
  
  .quiz-title {
    font-size: 39px;
    font-weight: bold;
    margin-left: 10px !important;
  }
  
  .play-now {
    border: 1px solid black !important;
  
    border-radius: 20px;
    background-color: #01FF76;
    border: none;
 
    font-size: 10px;
    cursor: pointer;
    align-self: flex-end; /* Aligns the button to the right */
    font-family: 'Montserrat', sans-serif; /* Applies the custom font */
  }
  
  .play-now:hover {
    background-color: #01FF76;
    color: white;
    border: 2px dotted black;
  }
  .timenow{
    height: 15px !important;
    width: 15px !important;
  }
  
}



