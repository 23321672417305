@media only screen and (max-width: 390px) and (max-height: 844px) {
@font-face {
  font-family: 'MontserratBlack';
  src: url('Fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url('Fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'DharmaGothicE';
  src: url('Fonts/DharmaGothicE-ExBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

.rank-container{
  font-size:1rem;
  font-weight: bold !important;
  margin-top: 20px !important;
}
.rank-container h1{
  font-size: 2.4rem;
  font-weight: bold !important;
  margin-top: -20px !important;
  font-family: 'DERegular', sans-serif; /* Applies the custom font */
  transform: scale(1.0, 1);   /* Expand horizontally */

  font-weight: 500;
}
.congrats-wrapper{
  margin-top: 100px;

}
.congrats-card {
  margin-top: 120px;
}
.attmpted{
  font-size: 1.3rem !important;
  font-family: 'DERegular', sans-serif; /* Applies the custom font */
  transform: scale(1.0, 1);   /* Expand horizontally */
  font-weight: 500;
  line-height: 1.3; /* 1.5 times the font size (24px line height) */
}

.congrats-card {
  margin-top: 50px !important;
}
}