
/* font-family: 'DharmaGothicE', sans-serif; Applies the custom font */


@media (max-width: 768px) {
  /* Styles for the congratulation page */
  .player-info {
    width: 95% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
    margin-bottom: 30px !important;
  }
  .player-detail {
    padding-right: 10px !important;
  }
  .leaderboard {
    width: 100% !important;

  }
  .player-icon {
    margin-left: 0px !important;
    margin-left: 15px !important;
    margin-top: -7px !important;
  }
  .player-detail {
    margin-right: 0px !important;
  }
  .player-name{
    font-size: 1rem;
    font-family: 'DERegular' !important;
  }
 
  .outer-span {
    width: 15% !important;
    font-size: 1.35rem;
  }
  .inner-span {
    font-size: 0.6rem;
    padding: 2px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    white-space: normal;
    
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
  }
  .inner-span-2 {
    padding-top: 15px !important;
  }
  .outer-span-name {
    width: 30% !important;
    font-size: 14px;
  }
  .player-icon {
    padding-right: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 100px;
  }
  /* First place */
  .first-place {
    height: 150px;
    background-color: #01FF76;
    width: 150px;
    z-index: 2;
  }

  /* Second place */
  .second-place {
    height: 120px;
    background-color: grey;
    width: 110px;
    z-index: 1;
  }

  /* Third place */
  .third-place {
    height: 100px;
    background-color: grey;
    width: 100px;
    z-index: 0;
  }
  .insideimg{
    color: #000;
    font-family: 'DharmaGothicE';
    font-size: 1.4rem;
  }
  /* Adding depth to podium cubes */

  .first-place img,
  .second-place img,
  .third-place img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .first-place span,
  .second-place span,
  .third-place span {
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
  }
  .game-points {
    font-size: 1rem !important;
    color: #ffd700; /* Gold color for game points */
    font-family: 'DERegular' !important;
  }

  .podium-info-1 {
    top: -40px !important; /* Adjust as needed to position the text above the podium */
    width: 70px;
    font-family: 'DERegular' !important;
  }
  .podium-info-2 {
    top: -30px !important; /* Adjust as needed to position the text above the podium */
    width: 70px;
    font-family: 'DERegular' !important;
  }
  .podium-info-3 {
    width: 70px;
    top: -20px !important; /* Adjust as needed to position the text above the podium */
    font-family: 'DERegular' !important;
  }
  
  .emptygraybox {
    width: 95%;
    height: 200px;
   
    position: absolute; /* Position relative to the nearest positioned ancestor */
    top: 600px; /* Adjust to place it below the podium */
    left: 50%; /* Horizontally center it */
    transform: translateX(-50%); /* Center horizontally */
    z-index: 0;
    border-radius: 20px;
    background-color: rgba(211, 211, 211, 0.2);
  }
  .emptyblackbox {
    width: 100%;
    height: 50px;

    position: absolute; /* Position relative to the nearest positioned ancestor */
    top: 750px; /* Adjust to place it below the podium */
    left: 50%; /* Horizontally center it */
    transform: translateX(-50%); /* Center horizontally */
    z-index: 0;
    
  }
  .outspan{
    font-size: 20px !important;
  }
  .outspantxt{
    font-size: 1.3rem !important;
  }
  .leaderboard-header h1 {
    font-size: 2rem;
    color: #01FF76; /* Neon green color */

  }
  .podiumtextrank{
    font-family: 'DERegular' !important;
    font-size: 1rem !important;
    padding-right: 10px !important;
  }
  .podiumtextrankcenter{
    padding-right: 0px !important;
  }
  .podium {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1; 
    width: 100%;
    margin-top: 130px;
    perspective: 1000px; /* This adds perspective for the 3D effect */
  }
  .telegram-icon{
    display: hide;
  }
}
