@font-face {
  font-family: 'MontserratBlack';
  src: url('Fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: url('Fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DharmaGothicE';
  src: url('Fonts/DharmaGothicE-ExBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

/* Applying custom font for static quiz elements */
@media only screen and (min-device-width: 393px) and (max-device-width: 430px) and (min-device-height: 852px) and (max-device-height: 932px) and (-webkit-device-pixel-ratio: 3) {

  .staticquizs .quiz-card-container {
    margin: 20px !important;
    margin-top: 50px !important;
  }

  .staticquizs .quiz-timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .staticquizs .quiz-question-number {
    margin-bottom: 5px !important;
    font-size: 1.3rem;
    font-family: 'DERegular' !important;
    margin-top: 10px !important;
  }

  .staticquizs .quiz-question {
    color: #FFF;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    background-color: black;
    border-radius: 20px;
    font-family: 'DERegular', sans-serif;
  }

  .staticquizs .quiz-card-container {
    min-height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #01FF76;
    padding: 20px !important;
    border-radius: 30px;

    /* Base green color */
    background-color: #01FF76;

    /* Circular rings with lighter shades of the base color */
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.15) 10%, transparent 10%),
                      radial-gradient(circle, rgba(255, 255, 255, 0.10) 20%, transparent 20%),
                      radial-gradient(circle, rgba(255, 255, 255, 0.05) 30%, transparent 30%),
                      radial-gradient(circle, rgba(255, 255, 255, 0.03) 40%, transparent 40%);

    /* Positioning the rings */
    background-position: center;
    background-size: 80% 80%, 60% 60%, 40% 40%, 20% 20%;
  }

  .staticquizs .cricwin-header {
    font-size: 2.4rem !important;
    margin-top: -10px !important;
    font-family: 'DERegular' !important;
  }

  .staticquizs .quiz-options .btn:hover {
    background-color: #ffffff; /* White on hover */
    color: #000000;
    font-family: 'DERegular' !important;
  }

  .staticquizs .quiz-options .btn {
    font-size: 1.5rem;
    border-radius: 25px;
    padding: 10px;
    border: none;
    box-shadow: none;
    margin-bottom: 10px;
    width: 100%;
    background-color: #000000; /* Initial black color */
    color: #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-family: 'DERegular' !important;
  }

  .staticquizs .quiz-options .btn.blink {
    animation: blink 1s infinite; /* Blinks between black and white */
    font-family: 'DERegular' !important;
  }

  /* Disable buttons when clicked */
  .staticquizs .quiz-options .btn.disabled {
    background-color: #ffffff;
    color: #000000;
    cursor: not-allowed;
    pointer-events: none; /* Disable click events */
    font-family: 'DERegular' !important;
  }

  .staticquizs {
    font-family: 'DERegular' !important;
  }
}
