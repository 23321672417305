/* fonts.css */

@font-face {
    font-family: 'gothic';
    src: url('Fonts/Montserrat-Bold.ttf') format('opentype');
}

@font-face {
    font-family: 'MontserratRegular';
    src: url('Fonts/Montserrat-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'DharmaGothicCBold';
    src: url('Fonts/DharmaGothicC-Bold.ttf') format('opentype');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('Fonts/Montserrat-Bold.ttf') format('opentype');
}

@font-face {
    font-family: 'DE';
    src: url('Fonts/DharmaGothicEExBold.otf') format('opentype');
}

@font-face {
    font-family: 'DERegular';
    src: url('Fonts/DharmaGothicERegular.otf') format('opentype');
}





