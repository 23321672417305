@media (max-width: 768px) {
  .live-quiz .content-container {
    display: flex;
    flex-direction: column !important;  
    margin-top: 0px !important;    
  }


  .live-quiz .youtube-container {
    width: 100vw !important;                  /* Full width for video container */
    margin-bottom: 0px !important;          /* Space between video and chat */
    height: 28vh !important;    
  }

  .live-quiz .youtube-live-chat {
           /* Margin above live chat */
    margin-left: 0px !important;             /* Reset left margin */
    width: 100% !important;                  /* Full width for chat container */
  }

  .live-quiz .youtube-container iframe {
    width: 100% !important;                  /* Full width for video iframe */
    height: 28vh !important;                 /* Adjust height for mobile view */
  }

  .live-quiz .youtube-live-chat iframe {
    width: 100% !important;                  /* Full width for chat iframe */
           /* Set height for live chat iframe */
           height: 57vh !important; /* Full height for video iframe */
  }
  

  .quizcard {
    margin-top: 30px !important;            /* Adjust margin for quiz card */
  }
  .live-quiz .quiz-card {
    width: 100% !important;
    max-width: 100vw;
margin-top: 10px !important;
padding: 0px !important;
  }
  .quiz-card-container {
    margin: 35px !important;                 /* Set margin for quiz card container */
         /* Set top margin for quiz card container */
  }
 
 
  .live-quiz .circle-svg {
    width: 70px !important;
    height: 60px !important;
    transform: rotate(-90deg);
  }
  .live-quiz .circle-background {
    fill: none;
    stroke: #ddd; /* Light grey background for the circle */
    stroke-width: 5;
    cx: 27; /* Horizontal center */
    cy: 27; /* Vertical center */
    r: 25; /* Radius for the background circle */
  }
  
  .live-quiz .circle-progress {
    fill: none;
    stroke: #4caf50; /* Green progress color */
    stroke-width: 5;
    stroke-linecap: round;
    cx: 27; /* Horizontal center */
    cy: 27; /* Vertical center */
    r: 25; /* Radius for the progress circle */
    stroke-dasharray: 157; /* Circumference of the circle */
    stroke-dashoffset: 157; /* Start with the entire circle hidden */
    transition: stroke-dashoffset 1s linear; /* Smooth transition */
  }
  
  .live-quiz .timer-text {
    font-size: 16px;
    font-weight: bold;
    fill: #333;
    text-anchor: middle;
    dominant-baseline: middle;
    transform: translate(27px, 32px); /* Center the text inside the circle */
  }
  .live-quiz .timer-text {

    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.3rem;
    font-weight: bold;
    color: black;
    background-color: #01FF76;
  }
  .cricwin-header{
    display: none !important;
  }
  .quiz-timer-container {
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Center within its container */
  }

  .liveequiz .quiz-question-number {
    margin-bottom: 5px !important;           /* Margin below question number */
    font-size: 1rem !important;            /* Set font size */
    font-family: 'DERegular' !important;     /* Set font family */
  }

  .liveequiz .quiz-question {
    color: #FFF !important;                   /* Set text color */
    font-size: 1.5rem !important;            /* Set font size */
    font-weight: bold !important;             /* Set font weight */
    text-align: center !important;            /* Center align text */
    padding: 10px !important;                 /* Add padding */
    background-color: black !important;       /* Set background color */
    border-radius: 20px !important;          /* Round corners */
    font-family: 'DERegular', sans-serif !important; /* Set font family */
  }
  .live-quiz .quiz-options .btn {
    font-size: 1.4rem;
  
  }

  .liveequiz .quiz-card-container {
    display: inline-block !important;
           /* Set minimum height */
               /* Enable flex layout */
    justify-content: center !important;       /* Center children horizontally */
    align-items: center !important;           /* Center children vertically */
    padding: 20px !important;                 /* Add padding */
    border-radius: 0px !important;          /* Round corners */
    background-color: #01FF76 !important;    /* Set background color */
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.15) 10%, transparent 10%) !important,
                      radial-gradient(circle, rgba(255, 255, 255, 0.10) 20%, transparent 20%) !important,
                      radial-gradient(circle, rgba(255, 255, 255, 0.05) 30%, transparent 30%) !important,
                      radial-gradient(circle, rgba(255, 255, 255, 0.03) 40%, transparent 40%) !important;
    background-position: center !important;   /* Center the background */
    background-size: 80% 80%, 60% 60%, 40% 40%, 20% 20% !important; /* Background size */
    margin-top: 38vh !important;
    height: 65vh !important;
  }
  

  .liveequiz .cricwin-header {
    font-size: 2rem !important;               /* Set font size */
    margin-top: -10px !important;             /* Adjust top margin */
    font-family: 'DERegular' !important;      /* Set font family */
  }

  .liveequiz .staticquizs {
    font-family: 'DERegular' !important;      /* Set font family */
  }
}
