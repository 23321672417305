@font-face {
  font-family: 'MontserratBlack';
  src: url('Fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url('Fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'DharmaGothicE';
  src: url('Fonts/DharmaGothicE-ExBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
/* font-family: 'DharmaGothicE', sans-serif; Applies the custom font */

/* Styles for the congratulation page */

.congrats-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
   
    margin-top: 100px;
  }
  
  .congrats-card {
    background-color: #00ff73;
    border-radius: 20px;
    padding: 40px;
    text-align: center;
    color: #fff;
    width: 80%;
    max-width: 900px;
    position: relative;
    overflow: hidden;
    margin-top: 100px;
  }
  
  .medal-container {
    position: relative;
    display: inline-block;
  }
  
  .medal-frame {
    width: 150px;
    height: 150px;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .user-avatar {
    width: 80px;
    height: 80px;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 25px; /* Adjust this based on alignment */
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
  }
  
  .rank-container {
    margin-top: 80px;
    font-family: 'DharmaGothicE', sans-serif;
  }
  
  .rank-container h1 {
    font-size: 2.5em;
    color: #000;
  }
  
  .rank-container p {
    font-size: 1.2em;
    color: #333;
  }
  
  .rank-score {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .rank, .score {
    background-color: #000;
    padding: 10px 20px;
    border-radius: 10px;
  }
  
  .rank h2, .score h2 {
    font-size: 2.5em;
    color: #00ff73;
  }
  
  .rank p, .score p {
    font-size: 1em;
    color: #ccc;
  }
  
  .hide {
    display: none;
  }

  .attmpted{
    font-size: 2rem !important;
    font-family: 'DharmaGothicE', sans-serif;
    
  }