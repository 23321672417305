.staticquizs .quiz-card-container {
  min-height: 86vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #01FF76;
  margin: 70px !important;
  margin-top: 0px !important;
  padding: 70px !important;
  border-radius: 30px;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 10%),
                    radial-gradient(circle, rgba(255, 255, 255, 0.1) 20%, transparent 20%),
                    radial-gradient(circle, rgba(255, 255, 255, 0.1) 30%, transparent 30%),
                    radial-gradient(circle, rgba(255, 255, 255, 0.1) 40%, transparent 40%);
  background-position: center;
  background-size: 80% 80%, 60% 60%, 40% 40%, 20% 20%;
}

.staticquizs .quiz-card {
  width: 100%;
  max-width: 100vw;
  padding: 0px;
}

.staticquizs .quiz-timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.staticquizs .quiz-timer {
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.staticquizs .circle-svg {
  width: 100px;
  height: 100px;
  transform: rotate(-90deg);
}

.staticquizs .circle-background {
  fill: black;
  stroke: #01FF76;
  stroke-width: 8px;
  border: 2px solid black;
}

.staticquizs .circle-progress {
  fill: none;
  stroke: #01FF76;
  stroke-width: 38px;
  stroke-dasharray: 283;
  stroke-dashoffset: 283;
  transition: stroke-dashoffset 1s linear;
}

.staticquizs .timer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: #00C853;
  background-color: black;
}

.staticquizs .quiz-options .btn {
  font-size: 1.5rem;
  border-radius: 25px;
  padding: 10px;
  border: none;
  box-shadow: none;
  margin-bottom: 10px;
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.staticquizs .quiz-options .btn:hover {
  background-color: #ffffff;
  color: #000000;
}

.staticquizs .quiz-options .btn.blink {
  animation: blink 1s infinite;
}

.staticquizs .quiz-options .btn.disabled {
  background-color: #ffffff;
  color: #000000;
  cursor: not-allowed;
  pointer-events: none;
}

.staticquizs .quiz-question-number {
  color: black;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.staticquizs .quiz-question {
  color: #FFF;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  background-color: black;
  border-radius: 20px;
}

.staticquizs .rank-container {
  margin-top: 10px !important;
}

.staticquizs .embed-responsive-item {
  height: 600px !important;
  width: 80% !important;
  margin: 30px;
}

.staticquizs .quizcard {
  display: block;
}

.staticquizs .quizvideo {
  display: none;
}
