@font-face {
  font-family: 'MontserratBlack';
  src: url('Fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url('Fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'DharmaGothicE';
  src: url('Fonts/DharmaGothicE-ExBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
/* font-family: 'DharmaGothicE', sans-serif; Applies the custom font */

@media only screen and (max-width: 390px) and (max-height: 844px) {
    /* Styles for the congratulation page */
    .podiumtextrank{
      font-family: 'DERegular' !important;
      font-size: 1.1rem !important;
      padding-right: 30px !important;
      font-weight: 800;
    }
  
    .podiumtextrankcenter{
      padding-right: 0px !important;
      font-family: 'DERegular' !important;
    }
    .topboard {
      margin-top: 20px !important;
    }
    .player-info {
      width: 95% !important;
      margin-left: 2% !important;
      margin-right: 2% !important;
      margin-bottom: 30px !important;
      font-family: 'DERegular' !important;
    }
    .rank4{
      margin-top: -4px !important;
    }
  
    .player-detail {
      padding-right: 10px !important;
      font-family: 'DERegular' !important;
      font-weight: 600;
    }
    .leaderboard {
      width: 100% !important;
      font-family: 'DERegular' !important;
    }
    .player-icon {
      margin-left: 0px !important;
      margin-left: 15px !important;
      margin-top: -7px !important;
      font-family: 'DERegular' !important;
    }
    .player-detail {
      margin-right: 0px !important;
      font-family: 'DERegular' !important;
    }
    .outer-span {
      width: 15% !important;
      font-family: 'DERegular' !important;
      font-size: 1.35rem;
    }
    .inner-span {
      font-size: 0.8rem;
      padding: 4px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      white-space: normal;
      font-family: 'DERegular' !important;
      justify-content: center; /* Centers horizontally */
      align-items: center; /* Centers vertically */
      
    }
  
    .inner-span-2 {
      padding-top: 15px !important;
      font-family: 'DERegular' !important;
    }
    .outer-span-name {
      width: 30% !important;
      font-size: 14px;
      font-family: 'DERegular' !important;
    }
    .player-icon {
      padding-right: 5px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      justify-content: center !important;
      align-items: center !important;
      font-family: 'DERegular' !important;
      margin-left: 100px;
    }
    /* First place */
    .first-place {
      height: 150px;
      background-color: #01FF76;
      font-family: 'DERegular' !important;
      width: 150px;
      z-index: 2;
    }
  
    /* Second place */
    .second-place {
      height: 120px;
      background-color: grey;
      font-family: 'DERegular' !important;
      width: 110px;
      z-index: 1;
    }
  
    /* Third place */
    .third-place {
      height: 100px;
      background-color: grey;
      font-family: 'DERegular' !important;
      width: 100px;
      z-index: 0;
    }
    .insideimg{
      color: #000;
      font-family: 'DERegular', sans-serif; /* Applies the custom font */
      font-weight: 500;
      font-size: 1.7rem;
      font-weight: 600;
    }
    /* Adding depth to podium cubes */
  
    .first-place img,
    .second-place img,
    .third-place img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  
    .first-place span,
    .second-place span,
    .third-place span {
      color: white;
      font-weight: bold;
      font-size: 1.5rem;
    }
    .game-points {
      font-size: 1rem !important;
      color: #ffd700; /* Gold color for game points */
    }
  
    .podium-info-1 {
      top: -30px !important; /* Adjust as needed to position the text above the podium */
      width: 70px;
    }
    .podium-info-2 {
      top: -20px !important; /* Adjust as needed to position the text above the podium */
      width: 70px;
    }
    .podium-info-3 {
      width: 70px;
      top: -20px !important; /* Adjust as needed to position the text above the podium */
    }
    
    .emptygraybox {
      width: 95%;
      height: 200px;
      background-color: #3A3A3A;
      position: absolute; /* Position relative to the nearest positioned ancestor */
      top: 490px; /* Adjust to place it below the podium */
      left: 50%; /* Horizontally center it */
      transform: translateX(-50%); /* Center horizontally */
      z-index: 0;
      border-radius: 20px;
      background-color: rgba(211, 211, 211, 0.2);
    }
    .emptyblackbox {
      width: 100%;
      height: 50px;
  
      position: absolute; /* Position relative to the nearest positioned ancestor */
      top: 750px; /* Adjust to place it below the podium */
      left: 50%; /* Horizontally center it */
      transform: translateX(-50%); /* Center horizontally */
      z-index: 0;
      
    }
    .outspan{
      font-size: 20px !important;
    }
    .outspantxt{
      font-size: 1.2rem !important;
      margin-top: 0px !important;
    }
    .leaderboard-header {
      margin-top: 30px;
    }
    .leaderboard-header h1 {
      font-size: 2.5rem;
      color: #01FF76; /* Neon green color */
      margin-top: 0px !important;
      font-family: 'DERegular' !important;
    }
    .podium{
      margin-top: 90px !important;
    }
    .secondnumber {
      font-family: 'DERegular' !important;
    }
    .firstnumber {
      font-family: 'DERegular' !important;
    }
    .thirdnumber {
      font-family: 'DERegular' !important;
    }
    .telegram-icon{
      display: hide;
    }
  
}
