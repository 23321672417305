@font-face {
  font-family: 'MontserratBlack';
  src: url('Fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url('Fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'DharmaGothicE';
  src: url('Fonts/DharmaGothicE-ExBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@media (max-width: 768px) {
  .rank-container{
    font-size:1.2rem;
    font-weight: bold !important;
    margin-top: 20px !important;
  }
  .congrats-wrapper{
    margin-top: 100px;

  }
  .congrats-card {
    margin-top: 120px;
  }
  .attmpted{
    font-size: 1rem;
    font-family: 'DharmaGothicE', sans-serif;
  }
}