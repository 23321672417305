@font-face {
  font-family: 'MontserratBlack';
  src: url('Fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'MontserratThin';
  src: url('Fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url('Fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'DharmaGothicE';
  src: url('Fonts/DharmaGothicE-ExBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
/* font-family: 'DharmaGothicE', sans-serif; Applies the custom font */


/* Styles for the congratulation page */
.congrats-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 90vh;
  color: white;
  font-family: 'Arial', sans-serif;
  margin: 0px;
  margin-bottom: 70px !important;
}


.leaderboard-header {
  margin-top: 70px;
  font-weight: bold;
  font-family: 'DharmaGothicE', sans-serif; /* Applies the custom font */
}

.leaderboard-header h1 {
  font-size: 3rem;
  color: #01FF76; /* Neon green color */
  font-family: 'DharmaGothicE', sans-serif; /* Applies the custom font */
}

.player-info {
  display: flex;
  justify-content: space-around;
  width: 1000px;
  margin-top: 60px;
  background-color: #01FF76;
  padding: 0px 0;
  border-radius: 40px;
  font-size: 20px;
  z-index: 1;
}

.podiumtextrankcenter{
  padding-right: 0px !important;
}

.player-info-2 {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
  padding: 10px 0;
  border-radius: 10px;
  margin-top: -100px;
  height: 30px;
  font-size: 10px;
}

.player-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding-right: 70px;
  z-index: 1;
}

.titleplayer {
  position: absolute;
  top: -100px; /* Adjust as needed to move the title */
  left: 0;
  width: 100%;
  font-size: 0.8rem; /* Adjust font size as needed */
  color: #01FF76; /* Neon green color */
  background-color: #fff; /* Background color to match span background */
  text-align: center;
  font-weight: bold;
  padding: 2px 0;
  border-radius: 5px;
  border-bottom: 1px solid #01FF76; /* Optional border for title */
}

.titleplayer-2 {
  position: absolute;
  top: -100px; /* Adjust as needed to move the title */
  left: 0;
  width: 100%;
  font-size: 0.8rem; /* Adjust font size as needed */
  color: #01FF76; /* Neon green color */
  background-color: #fff; /* Background color to match span background */
  text-align: center;
  font-weight: bold;
  font-size: 2px;
  padding: 2px 0;
  border-radius: 5px;
  border-bottom: 1px solid #01FF76; /* Optional border for title */
}

/* Optional: Adjustments to player-icon if needed */
.player-icon {
  padding-right: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 100px;
  margin-top: -12px;
}

/* Podium container */
.podium {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1; 
  width: 100%;
  margin-top: 110px;
  perspective: 1000px; /* This adds perspective for the 3D effect */
}

/* Podium stage with 3D effect */
.podium-stage {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

/* Each podium block (first, second, third) */
.podium-stage div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -5px; /* Make podium blocks stick together */
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(15deg); /* Adds the 3D angle */
  border-radius: 5px;
  box-shadow: -10px 10px 15px rgba(0, 0, 0, 0.4);
}

/* First place */
.first-place {
  height: 200px;
  background-color: #01FF76;
  width: 300px;
  z-index: 2;
  font-size: 1rem !important;
}

/* Second place */
.second-place {
  height: 150px;
  background-color: grey;
  width: 200px;
  z-index: 1;
}

/* Third place */
.third-place {
  height: 100px;
  background-color: grey;
  width: 170px;
  z-index: 0;
}

/* Adding depth to podium cubes */


.first-place img, .second-place img, .third-place img {
  width: 50px;
  height: 50px;
  border-radius: 50%;

}

.first-place span, .second-place span, .third-place span {
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

.outer-span {
  display: flex; /* Flexbox will help center the content */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  position: relative; /* Allows positioning of inner span */
  font-weight: bold;
  background-color: #01FF76; /* Background color of the outer span */
  color: black;
  margin: 0px !important;
  padding: 0px !important;
  text-align: center;
  font-family: 'DharmaGothicE';
  font-size: 2rem;
}

.inner-span {
  position: absolute; /* Positioned absolutely relative to the outer span */
  top: -30px; /* Position above the outer span */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to exact center */
  font-size: 0.9rem; /* Adjust font size as needed */
  color: #01FF76; /* Color of the inner text */
  background-color: #000; /* Background to match the parent span */
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  border-bottom: 1px solid #01FF76; /* Optional border for the title */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add ellipsis if the text overflows */
  margin: 0px !important;
  padding: 4px !important;
  height: auto !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
  font-family: 'DharmaGothicE'
}
.insideimg{
  color: #000;

  font-size: 2rem;
  font-family: 'DharmaGothicE', sans-serif;
}


.firstnumber{
  font-size: 3.5rem !important;
  font-family: 'DharmaGothicE' !important;
  color: black !important;
}

.secondnumber{
  font-size: 2.5rem !important;
  font-family: 'DharmaGothicE'
}
.thirdnumber{
  font-family: 'DharmaGothicE'
}

/* Podium player info container */
.podium-info {
  text-align: center;
  position: absolute;
  
}

.player-name {
  font-size: 0.8rem !important;
  color: #fff;
  background-color: #3A3A3A;
  padding: 5px;
  border-radius: 20px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.game-points {
  font-size: 1.2rem !important;
  color: #FFD700; /* Gold color for game points */
  font-family: 'DharmaGothicE', sans-serif;
}

.podium-info-1{

  top: -80px; /* Adjust as needed to position the text above the podium */
}
.podium-info-2{
  top: -50px; /* Adjust as needed to position the text above the podium */
}
.podium-info-3{
  top: -20px; /* Adjust as needed to position the text above the podium */
}
.rank4{
  margin-top: -5px;
  width: 700px !important;
  margin-left: 150px;
  z-index: 2;
}

.rank5{
  margin-top: 65px;
  width: 700px !important;
  margin-left: 150px;
  z-index: 1; 
}

.emptygraybox {
  width: 80%;
  height: 200px;
  background-color: #3A3A3A;
  position: absolute; /* Position relative to the nearest positioned ancestor */
  top: 642px; /* Adjust to place it below the podium */
  left: 50%; /* Horizontally center it */
  transform: translateX(-50%); /* Center horizontally */
  z-index: 0;
  border-radius: 20px;
  background-color: rgba(211, 211, 211, 0.2);
  margin-bottom: 100px !important;
}


.rank4, .rank5, .emptygraybox .podium {
  position: absolute; /* Ensure relative positioning for proper stacking context */
}
.podiumtextrank{
  font-family: 'DharmaGothicE', sans-serif;
  font-size: 1.25rem !important;
}
