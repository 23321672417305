.live-quiz .quiz-card {
  width: 100%;
  max-width: 100vw;
  padding: 50px;
}
.live-quiz .youtube-container iframe {
  width: 900px;  /* Set your desired width */
  height: 500px; /* Set your desired height */
}

.live-quiz .quiz-timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.live-quiz .quiz-timer {
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.live-quiz .circle-svg {
  width: 100px;
  height: 100px;
  transform: rotate(-90deg);
}
.circle-svg circle {
  cx: 50;
  cy: 50;
}

.circle-background,
.circle-progress {
  r: 45;
}

.live-quiz .circle-background {
  fill: black;
  stroke: #00C853;
  stroke-width: 8px;
  border: 2px solid black;
}

.live-quiz .circle-progress {
  fill: none;
  stroke: white;
  stroke-width: 10px;
  stroke-dasharray: 283; /* 2 * PI * r (where r = 45) */
  stroke-dashoffset: 283;
  transition: stroke-dashoffset 1s linear;
}

.live-quiz .rank-container {
  margin-top: 10px !important;
}

.live-quiz .timer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: #00C853;
  background-color: black;
}

.live-quiz .cricwin-header {
  font-size: 2rem;
  color: black;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px !important;
  font-family: 'MontserratBlack', sans-serif; /* Applies the custom font */
}

.live-quiz .embed-responsive {
  margin-bottom: 20px;
}

.live-quiz .embed-responsive-item {
  height: 600px !important;
  width: 80% !important;
  margin: 30px;
}

.live-quiz .quiz-question-number {
  color: black;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'MontserratSemiBold', sans-serif;
}

.live-quiz .quiz-question {
  color: #00C853;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  background-color: black;
  border-radius: 20px;
  font-family: 'DERegular', sans-serif !important; 
  font-weight: normal !important;;
}

/* Base styles for buttons */
.live-quiz .quiz-options .btn {
  font-weight: normal !important;;
  font-size: 1.5rem;
  border-radius: 25px;
  padding: 10px;
  border: none;
  box-shadow: none;
  margin-bottom: 10px;
  width: 100%;
  background-color: #000000; /* Initial black color */
  color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: 'DERegular', sans-serif !important; 
}

/* Hover state */
.live-quiz .quiz-options .btn:hover {
  background-color: #ffffff; /* White on hover */
  color: #000000;
  font-family: 'DharmaGothicE', sans-serif;
}

/* Blinking animation */
@keyframes blink {
  0% {
    background-color: #000000; /* Black */
    color: #ffffff;
  }
  50% {
    background-color: #ffffff; /* White */
    color: #000000;
  }
  100% {
    background-color: #000000; /* Back to Black */
    color: #ffffff;
  }
}

/* Apply blinking effect on click for selected button */
.live-quiz .quiz-options .btn.blink {
  animation: blink 1s infinite; /* Blinks between black and white */
}

/* Disable buttons when clicked */
.live-quiz .quiz-options .btn.disabled {
  background-color: black;
  opacity: 1;
  color: white;
  cursor: not-allowed;
  pointer-events: none; /* Disable click events */
}

.live-quiz .quizcard {
  display: block;
}

.live-quiz .quizvideo {
  display: none;
}

.live-quiz .youtube-container {
  min-height: 0px !important;
}

.live-quiz #yt-live-chat-app {
  min-height: 0px !important;
}

.live-quiz .content-container {
  display: flex;
  justify-content: space-between;
}

.live-quiz .youtube-container,
.live-quiz .youtube-live-chat {

  margin-right: 20px; /* Optional: Adds space between video and chat */
}

.live-quiz .youtube-live-chat iframe {
  width: 25vw; /* Adjust to fill the flex container */
  height: 84vh;
  margin-left: 0px !important;
}

.live-quiz .youtube-container iframe {
  width: 75vw !important;
  height: 84vh;
}

.live-quiz .outermost {
  position: relative;
}


.live-quiz .quiz-card-container {
  height: 84vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00C853;
  margin: 0px !important;
  padding: 0px !important;
  border-radius: 0px !important;
  /* Base green color */
  background-color: #00C853;
  
  /* Circular rings */
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 10%),
                    radial-gradient(circle, rgba(255, 255, 255, 0.1) 20%, transparent 20%),
                    radial-gradient(circle, rgba(255, 255, 255, 0.1) 30%, transparent 30%),
                    radial-gradient(circle, rgba(255, 255, 255, 0.1) 40%, transparent 40%);
  
  /* Positioning the rings */
  background-position: center;
  background-size: 80% 80%, 60% 60%, 40% 40%, 20% 20%;
  margin-top: 90px !important;
}