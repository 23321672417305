
.mb-0{
  font-size: 1.2rem;
}
.mb-1{
  font-size: 1.1rem;
}
.header {
  position: relative;
  height: auto;
  display: flex;
  justify-content: center; /* Centers the box horizontally */
}
.toplevelclas{
  color: rgba(0, 0, 0); /* Black text with 50% opacity */
  padding-bottom: 100px;
}
.quiname{
  color: rgba(0, 0, 0,0);
}

.header-box {
  width: 80%;
  position: absolute;
  top: 70px; /* Positions the box 70px from the top */
  height: auto; /* Height of the box */
  border: 2px solid #01FF76; /* Outline of the box */
  box-sizing: border-box; /* Ensures padding and border are included in width and height */
  background-color: #01FF76;
  display: flex;
  align-items: center; /* Vertically centers items */
  padding: 30px; /* Adds padding inside the header-box */
  border-radius: 20px;
  margin-bottom: 30px;
  padding-left: 60px !important;
}

.header-text-box{
  width: auto;
  margin-left: 50px;
  display: inline-block;
  vertical-align: top; /* Aligns the box with the top of its container */
  text-align: left;
}
.hero-image-style{
  position: absolute; /* Allows the image to overflow outside the span */
  top: -10; /* Adjust positioning as needed */
  left: 0; /* Adjust positioning as needed */
  width: 700px!important;
  height: 350px!important;
  margin-top: -180px;
  margin-left: -40px;

}

.header-text-box-right{
  width: auto;
  margin-left: 50px;
  display: inline-block;
  vertical-align: top; /* Aligns the box with the top of its container */
  text-align: right;
}


.header-box .top-text {
  text-align: left; /* Aligns the text to the left */
  font-size: 2rem; /* Adjusts the text size */
  margin-bottom: -30px;
  font-weight: bold;
  font-family: 'DharmaGothicE', sans-serif;
  transform: scale(1.2, 1);   /* Expand horizontally */
  transform-origin: left; 
}

.header-box .main-text {
  text-align: left; /* Center-aligns the text */
  font-size: 80px; /* Larger text size */
  font-weight: bold; /* Makes the text bold */
  font-family: 'DharmaGothicE', sans-serif;
  transform: scale(1.2, 1);   /* Expand horizontally */
  transform-origin: left; 
}

.header-box .bottom-text {
  display: flex;
  justify-content: space-between; /* Distributes space between items */
  align-items: center; /* Aligns items vertically */
  font-weight: bold;
  font-family: 'DharmaGothicE', sans-serif;
  margin-top: -25px;
  transform: scale(1.2, 1);   /* Expand horizontally */
  transform-origin: left; 
}

.header-box .bottom-text .left-text {
  text-align: left; /* Aligns text to the left */
  font-size: 1.6rem; /* Small text size */
}

.header-box .bottom-text .right-text {
  text-align: right; /* Aligns text to the right */
  font-size: 34px; /* Small text size */

}


.img-quiz{
  display: inline-block; /* Ensure span takes only as much width as needed */
  overflow: visible; /* Allows content to overflow outside */
  position: relative; /* Positions children absolutely relative to the span */

}


.content {
  display: flex;
  overflow-x: auto; /* Enables horizontal scrolling */
  scroll-snap-type: x mandatory; /* Adds snapping behavior */
  margin-top: 350px; /* Creates space below the header */
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px; /* Adds space below the scroll bar */
  overflow-y: hidden; /* Disables vertical scrolling */
}

.content-box {
  background-color: #01FF76;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 34%;
  margin-left: 20px;
  flex: 0 0 auto;
  height: 190px;
  border: 2px solid black;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  scroll-snap-align: start;
  position: relative;
  padding-top: 45px!important;
}

.box-number {
  width: 30%;
  font-size: 170px;
  font-weight: bold;
  margin-right: 20px;
  margin-top: -140px;
  height: 130px;
  font-family: 'DharmaGothicE', sans-serif;
 
}

.box-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.quiz-title {
  font-size: 2.7rem;
  font-weight: bold;
  font-family: 'DharmaGothicE', sans-serif;
}

.play-now {
  border: 2px dotted black !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 20px;
  background-color: #01FF76;
  border: none;
  padding: 2px;
  font-size: 10px;
  cursor: pointer;
  align-self: flex-end; /* Aligns the button to the right */
}

.play-now:hover {
  background-color: #01FF76;
  color: white;
  border: 2px dotted black;
}


.timenow{
  height: 25px !important;
  width: 25px !important;
  margin-top: -3px;
}