@font-face {
  font-family: 'MontserratBlack';
  src: url('Fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: url('Fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DharmaGothicE';
  src: url('Fonts/DharmaGothicE-ExBold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@media (max-width: 768px) {
  .staticquizs .quizcard {
    margin-top: -20px;
  }

  .staticquizs .quiz-card-container {
    margin: 20px !important;
    margin-top: 50px !important;
  }

  .staticquizs .quiz-timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .staticquizs .quiz-question-number {
    margin-bottom: 5px !important;
    font-size: 1.3rem;
    font-family: 'DERegular' !important;
    margin-top: 10px !important;
  }

  .staticquizs .quiz-question {
    color: #FFF;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    background-color: black;
    border-radius: 20px;
    font-family: 'DERegular', sans-serif;
  }

  .staticquizs .quiz-card-container {
    min-height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px !important;
    border-radius: 30px;
    
    /* Base green color */
    background-color: #01FF76;

    /* Circular rings with lighter shades of the base color */
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.15) 10%, transparent 10%),
                      radial-gradient(circle, rgba(255, 255, 255, 0.10) 20%, transparent 20%),
                      radial-gradient(circle, rgba(255, 255, 255, 0.05) 30%, transparent 30%),
                      radial-gradient(circle, rgba(255, 255, 255, 0.03) 40%, transparent 40%);
    
    /* Positioning the rings */
    background-position: center;
    background-size: 80% 80%, 60% 60%, 40% 40%, 20% 20%;
  }

  .staticquizs .cricwin-header {
    font-size: 2rem !important;
    margin-top: -10px !important;
    font-family: 'DERegular' !important;
  }

  .staticquizs .staticquizs {
    font-family: 'DERegular' !important;
  }
}
